import { AnalyticsBrowser } from "@segment/analytics-next";

export const analytics = new AnalyticsBrowser();

// TODO verify this only mocks in development
//     This stops the `POST https://api.segment.io/v1/i net::ERR_CERT_COMMON_NAME_INVALID` error in dev
// const createAnalytics = () => {
//   if (process.env.NODE_ENV === "development") {
//     return {
//       load: () => Promise.resolve(),
//       identify: () => Promise.resolve(),
//       track: () => Promise.resolve(),
//       page: () => Promise.resolve(),
//       flush: () => Promise.resolve(),
//     };
//   }

//   return new AnalyticsBrowser();
// };

// export const analytics = createAnalytics();
